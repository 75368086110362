import type { ReactNode } from "react";
import React from "react";
import { PagePortalLogin } from "@aspen/pages";
import { getQueryValue } from "@aspen/libs";

class Login extends React.Component {
  // 只有从zendesk回调的页面才会涉及到这个方法, 放在web portal考虑，只从web portal入口
  customerHandlerAfterSuccessLogin = (data) => {
    let return_to = getQueryValue("return_to");
    if (!return_to) return;
    if (data?.jwtToken) {
      const rediect = `${return_to}?token=${data.user_name}`;
      window.location.href = rediect;
    }
  };

  render(): ReactNode {
    return (
      <PagePortalLogin customerHandlerAfterSuccessLogin={this.customerHandlerAfterSuccessLogin} />
    );
  }
}

export default Login;
